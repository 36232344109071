.headerAdmin {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px;
  background: rgb(67, 67, 206);

  .btn {
    border: 1px solid black;
    padding: 5px;
    margin: 5px;
    background: rgb(213, 213, 73);
    font-weight: bold;

    &:hover {
      background: rgb(60, 60, 33);
      color: white;
    }
  }
}
