.accordion-item {
  background-color: #fff;
  color: #111;
  margin: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'Times New Roman', Times, serif;

  @media (max-width: 1100px) {
    margin-left: 10px;
  }
}

.header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 22px;

  @media (max-width: 576px) {
    font-size: 22px;
  }
}

.header::after {
  content: '\002B';
  font-size: 2rem;
  position: absolute;
  right: 1rem;
}

.header.active::after {
  content: '\2212';
}

.item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 1px solid;
  border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
}
