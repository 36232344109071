.bookSearch {
  width: 100%;
  min-height: 100vh;

  .main {
    width: 100%;
    display: flex;

    .sidebar {
      flex: 1;
    }

    .content {
      flex: 5;
      padding: 5px;
    }
  }
}

.findBook {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
