.counter {
  display: flex;
  justify-content: space-around;
  padding: 0;
  line-height: 1;

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    align-items: center;
    width: 25%;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #f7f7f7;

    &:hover {
      cursor: pointer;
      position: relative;
      z-index: 2;
    }

    @media (max-width: 890px) {
      flex-direction: column;
      padding-top: 0;
      text-align: center;
    }

    @media (max-width: 576px) {
      width: 50%;
    }

    @media (max-width: 420px) {
      width: 100%;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;

      .item__message {
        position: absolute;
        top: 0;
        background: #fff;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 25px;
        padding-left: 5px;
        padding-right: 5px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        text-align: center;

        @media (max-width: 890px) {
          display: none;
        }
      }
      .text-number {
        font-size: 30px;
        font-weight: bold;
        padding-left: 10px;
        font-family: 'Times New Roman', Times, serif;
        line-height: 0.5;

        @media (max-width: 992px) {
          font-size: 26px;
        }
      }
      .text-desc {
        font-size: 18px;
        padding-left: 10px;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 0.1;
        font-family: 'Times New Roman', Times, serif;

        @media (max-width: 992px) {
          font-size: 16px;
        }

        @media (max-width: 992px) {
          font-size: 16px;
        }
      }
    }
  }

  .item__color {
    background: #c7e6d0;
  }

  .item__mini-col-1 {
    @media (max-width: 576px) {
      background: #c7e6d0;
    }
  }

  .item__mini-col-2 {
    @media (max-width: 576px) {
      background: #f7f7f7;
    }
  }

  .item__mini-1 {
    @media (max-width: 420px) {
      background: #f7f7f7;
    }
  }

  .item__mini-2 {
    @media (max-width: 420px) {
      background: #c7e6d0;
    }
  }

  .item:hover .item__message {
    top: -50px;
    left: 70px;
    opacity: 1;
    pointer-events: auto;
    font-weight: bold;
  }
}
