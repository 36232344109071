$button-width: 250px;
$dropdown-height: 100px;
$dropdown-link-color: black;
.navBar {
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;

    .logo {
      width: 150px;
    }

    .navigation {
      display: flex;
      align-items: center;

      @media (max-width: 715px) {
        display: none;
      }

      .item {
        font-family: Oswald, sans-serif;
        font-size: 25px;
        font-weight: 900;
        padding: 15px 25px;
        position: relative;
        text-decoration: none;
        color: black;

        &:hover {
          color: #67a27e;
          text-decoration: underline;
          font-weight: bold;
        }

        @media (max-width: 1024px) {
          font-size: 1.5rem;
        }

        @media (max-width: 992px) {
          font-size: 1.2rem;
          padding: 15px 15px;
        }
      }

      .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }

      .log-in-web {
        font-weight: bold;
        padding: 5px;
        margin-left: 10px;
        @media (max-width: 715px) {
          display: none;
        }

        .user__image {
          width: 40px;
          height: 40px;
          border-radius: 50px;
        }

        .dropdown-menu {
          position: absolute;
          top: 70px;
          right: 5px;
          z-index: 100;
          display: none;
          margin: 0 auto;
          padding: 0;
          list-style: none;
          background-color: #c7e6d0;
          border-radius: 4px;
          box-shadow: -5px 11px 22px -7px rgba(0, 0, 0, 0.32);
          transition: all 0.2s ease-in-out;
          color: $dropdown-link-color;

          li {
            width: 100%;
            padding: 10px;
            border-bottom: 1px solid white;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            display: flex;
            align-items: center;

            &:hover {
              background-color: #fa8e47;
            }

            .profile__link {
              width: 100%;
              color: $dropdown-link-color;
              text-decoration: none;
            }
          }
        }

        &:hover .dropdown-menu {
          display: block;
        }
      }
    }

    .burger {
      padding: 5px;
      cursor: pointer;
      background: transparent;
      border: none;
      outline: none;
      font-size: 1.8rem;
      display: none;
      color: green;

      @media (max-width: 715px) {
        display: block;
      }
    }

    .exit {
      position: fixed;
      padding: 5px;
      cursor: pointer;
      background: transparent;
      border: none;
      outline: none;
      font-size: 1.8rem;
      z-index: 100;
      right: 20px;
      top: 20px;

      @media (max-width: 850px) {
        display: block;
      }
    }

    .menu {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      background-color: #c7e6d0;
      padding-top: 35%;
      z-index: 100;

      .privacy {
        color: gray;
        text-align: center;
        padding: 30px;
        font-size: 11px;

        .privacy__link {
          color: gray;
        }
      }

      .item {
        margin: 0 1rem;
        color: black;
        text-decoration: none;
        font-size: 30px;
        font-weight: bold;

        &:hover {
          color: white;
        }
      }

      .icons {
        position: absolute;
        bottom: 30px;
      }

      .log-in-web {
        @media (max-width: 715px) {
          display: none;
        }
      }

      .google-auth-button {
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        height: 36px;
        width: $button-width;
        padding: 0 12px;
        border-radius: 4px;
        border: 1px solid #4285f4;
        background-color: #4285f4;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.2px;
        line-height: 34px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        img {
          position: absolute;
          left: 8px;
          top: 8px;
          width: 20px;
          height: 20px;
        }

        &:hover {
          background-color: #3b78e7;
          border-color: #3b78e7;
        }

        &:active {
          background-color: #3461c1;
          border-color: #3461c1;
        }
      }

      .user__profile__mobile {
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        height: 36px;
        width: $button-width;
        padding: 0 12px;
        border-radius: 4px;
        border: 1px solid #fa8e46;
        background-color: #fa8e46;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.2px;
        line-height: 34px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        span {
          position: absolute;
          left: 8px;
          top: 0;
          width: 20px;
          height: 20px;
        }

        .user__profile__mobile-button {
          text-decoration: none;
          background: none;
          border: none;
          color: white;
        }

        &:hover {
          background-color: #3b78e7;
          border-color: #3b78e7;
        }

        &:active {
          background-color: #3461c1;
          border-color: #3461c1;
        }
      }
    }
  }
}
