.progressBar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  .img {
    width: 120px;
    height: 120px;
    @media (max-width: 599px) {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 470px) {
      width: 80px;
      height: 80px;
    }
    @media (max-width: 370px) {
      width: 60px;
      height: 60px;
    }
  }

  .medalName {
    text-transform: uppercase;
    font-weight: bold;

    @media (max-width: 550px) {
      font-size: 14px;
    }

    @media (max-width: 430px) {
      font-size: 13px;
    }

    @media (max-width: 390px) {
      font-size: 11px;
    }
  }

  .circle {
    width: 120px;
    height: 120px;

    @media (max-width: 599px) {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 470px) {
      width: 80px;
      height: 80px;
    }

    @media (max-width: 370px) {
      width: 60px;
      height: 60px;
    }
  }
}
