%button {
  display: inline-block;
  background: #fa8e46;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  padding: 10px 25px;
  &:hover {
    background: #c7e6d0;
  }
}

.AlreadyReadBooks {
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-around;
  align-items: center;
  padding: 10px;

  @media (max-width: 796px) {
    flex-direction: column;
  }

  .books {
    .title {
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      align-items: center;

      @media (max-width: 420px) {
        padding-bottom: 10px;
      }

      .text {
        padding-left: 20px;
        font-size: 55px;
        font-family: "Oswald", sans-serif;
        font-weight: 700;
        color: #5bc1b3;
        text-transform: uppercase;

        @media (max-width: 870px) {
          font-size: 40px;
        }

        @media (max-width: 420px) {
          font-size: 30px;
        }
      }

      .read__more {
        @extend %button;
        font-size: 16px;
        padding: 5px 20px;
      }
    }

    .books__list {
      display: flex;

      .book {
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          height: 270px;
          width: 180px;

          @media (max-width: 1099px) {
            height: 220px;
            width: 145px;
          }

          @media (max-width: 899px) {
            height: 160px;
            width: 100px;
          }

          @media (max-width: 796px) {
            height: 220px;
            width: 145px;
          }

          @media (max-width: 576px) {
            height: 160px;
            width: 100px;
          }

          @media (max-width: 420px) {
            height: 110px;
            width: 70px;
          }
        }

        a {
          @extend %button;
          font-size: 18px;
          margin-top: 10px;
          padding: 5px 20px;

          @media (max-width: 496px) {
            font-size: 15px;
            padding: 5px 15px;
          }

          @media (max-width: 420px) {
            font-size: 12px;
            padding: 2px 10px;
          }
        }
      }
    }
  }

  .books__article {
    width: 350px;
    height: 350px;
    background: #c7e6d0;
    border-radius: 20px;
    position: relative;

    @media (max-width: 1099px) {
      width: 350px;
      height: 350px;
    }

    @media (max-width: 899px) {
      width: 300px;
      height: 300px;
    }

    @media (max-width: 796px) {
      margin-top: 15px;
    }

    @media (max-width: 396px) {
      width: 220px;
      height: 220px;
    }

    .book {
      position: relative;
      width: 100%;
      margin-top: 30px;

      @media (max-width: 396px) {
        margin-top: 0;
      }

      img {
        height: 160px;
        width: 100px;

        @media (max-width: 890px) {
          width: 75px;
          height: 120px;
        }
      }

      .first__book {
        position: absolute;
        left: 35%;
        top: 30px;
        transition: transform 0.7s;
        z-index: 1;

        &:hover {
          transform: translate(-1rem, -1em) rotate(0);
          top: 5px;
        }
      }

      .second__book {
        position: absolute;
        left: 55%;
        top: 20px;
        transform: rotate(10deg);
        transition: transform 0.7s;

        &:hover {
          transform: translate(-2rem, -1em) rotate(0);
          left: 73%;
          top: 45px;
          transition: 0.5s;
        }
      }

      .third__book {
        position: absolute;
        left: 15%;
        top: 20px;
        transform: rotate(-10deg);
        transition: transform 0.7s;

        &:hover {
          transform: translate(-2rem, 1em) rotate(0);
        }
      }
    }

    h4 {
      position: absolute;
      bottom: 30px;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      padding: 0 5px;
      background: #f7f7f7;
      padding: 5px 0;

      @media (max-width: 396px) {
        padding: 0;
        margin-top: 5px;
        font-size: 14px;
        bottom: 5px;
      }

      .link {
        color: black;
        text-decoration: none;

        &:hover {
          color: orange;
        }
      }
    }
  }
}
