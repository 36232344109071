.ProgressBar {
  text-align: center;
}

.percentage {
  background: #13A463;
  border-radius: 4px;
  color: #ffffff;
  padding: 5px 0;
  text-align: center;
}
