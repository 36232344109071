.uploadPanel {
  width: 100%;
  min-height: 100vh;

  .main {
    width: 100%;
    display: flex;

    .slider {
      flex: 1;
    }

    .content {
      flex: 5;
    }
  }
}
