.MonthReadBook {
  background: #f7f7f7;
  padding: 5px;
  .MonthReadBook__wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 1150px;
    margin: 0 auto;
    margin-top: 30px;

    padding: 10px 0;

    @media (max-width: 750px) {
      flex-direction: column;
      align-items: center;
    }

    .book {
      .blot {
        img {
          width: 400px;
          height: 400px;

          @media (max-width: 420px) {
            width: 300px;
            height: 300px;
          }
        }
      }

      .book__pic {
        position: absolute;
        left: 90px;
        top: 50px;

        @media (max-width: 750px) {
          left: 35%;
        }

        @media (max-width: 420px) {
          left: 30%;
          top: 30px;
        }

        img {
          width: 200px;
          height: 300px;

          @media (max-width: 420px) {
            width: 160px;
            height: 240px;
          }
        }
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 45px;
        font-family: "Oswald", sans-serif;
        font-weight: 700;
        color: #5bc1b3;
        padding-bottom: 10px;
        text-transform: uppercase;

        @media (max-width: 870px) {
          font-size: 35px;
          align-self: flex-start;
        }

        @media (max-width: 750px) {
          font-size: 30px;
          align-self: center;
        }

        @media (max-width: 390px) {
          font-size: 28px;
        }
      }

      h5 {
        font-weight: normal;
        padding: 5px;

        @media (max-width: 870px) {
          font-size: 18px;
        }

        @media (max-width: 750px) {
          padding: 10px;
        }

        @media (max-width: 390px) {
          font-size: 17px;
        }
      }

      button {
        margin-top: 10px;
        margin-bottom: 10px;
        display: inline-block;
        background: #fa8e46;
        color: white;
        text-decoration: none;
        border-radius: 10px;
        font-weight: bold;
        border: none;
        text-transform: uppercase;

        a {
          color: white;
          display: inline-block;
          width: 100%;
          height: 100%;
          text-decoration: none;
          padding: 15px 20px;
        }

        &:hover {
          background: #c7e6d0;
        }
      }
    }
  }
}
