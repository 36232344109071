.InfoAboutBooks {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 5px;
    border: none;
    background: gray;
    color: white;
    font-weight: bold;
  }

  button:disabled {
    opacity: 0.5; /* Уменьшает прозрачность кнопки до 50% */
    cursor: not-allowed; /* Устанавливает курсор в виде запрещающего знака */
  }
}
