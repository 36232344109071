.slider {
  .items {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 300px;

    @media (max-width: 577px) {
      height: 350px;
    }

    .itemTest {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 300px;
      position: relative;
      width: 250px;

      @media (max-width: 577px) {
        height: 350px;
        outline: none;
      }

      .front {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 1s;
        backface-visibility: hidden;
        background-color: #fff;
        border-radius: 20px;
      }

      .back {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 1s;
        backface-visibility: hidden;
        background-color: #fff;
        background-color: #f8bf39;
        border-radius: 20px;
        background-color: #c7e6d0;
        line-height: 0.5;
        justify-content: space-between;
        background-image: linear-gradient(to top, #c7e6d0, #b1ddc6, #98d4be, #7dcbb8, #5ec2b4);

        .number {
          font-size: 55px;
          font-family: 'Oswald', sans-serif;
          font-weight: 700;
          color: #1b6158;
          padding-top: 30px;
          padding-bottom: 10px;
        }

        .author,
        .genre,
        .web {
          font-family: 'Oswald', sans-serif;
          font-weight: 400;
          font-size: 20px;
        }

        .genre {
          padding-bottom: 15px;
        }

        .month {
          font-size: 20px;
          font-family: 'Oswald', sans-serif;
          font-weight: 400;
          color: #387e75;
        }

        .btn {
          margin-bottom: 10px;
          outline: none;
          border: none;

          &:active,
          &:hover,
          &:focus {
            border: none;
            outline: none;
          }

          button {
            border: none;
            background: none;
            outline: none;

            a {
              padding: 5px 15px;
              border-radius: 10px;
              font-family: 'Oswald', sans-serif;
              border: none;
              text-decoration: none;
              background: #5bc1b3;
              color: white;

              &:hover {
                background: #1b6158;
                outline: none;
              }
            }
          }
        }
      }
    }

    .slick-prev {
      position: absolute;
      top: 45%;
      left: -40px;
      height: 40px;
      width: 40px;
      padding-left: 10px;

      @media (max-width: 1220px) {
        left: -25px;
      }

      @media (max-width: 350px) {
        left: -15px;
      }
    }

    .slick-next {
      position: absolute;
      top: 45%;
      right: -40px;
      height: 40px;
      width: 40px;
      padding-right: 10px;

      @media (max-width: 1220px) {
        right: -25px;
      }

      @media (max-width: 350px) {
        right: -15px;
      }
    }
  }
}

.back {
  transform: rotateY(180deg);
}

.itemTest:hover .front {
  transform: rotateY(180deg);
}

.itemTest:hover .back {
  transform: rotateY(360deg);
}

.slick-track {
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 400px;

  .slick-slide {
    top: 40px;
    margin: 30px;
  }
}
