.mainHeader {
  .item {
    max-height: 700px;
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
    }

    .text {
      position: absolute;
      width: 400px;
      top: 45%;
      left: 30%;

      @media (max-width: 1499px) {
        left: 25%;
      }

      @media (max-width: 1280px) {
        left: 23%;
      }

      @media (max-width: 1120px) {
        left: 20%;
      }

      @media (max-width: 870px) {
        left: 15%;
      }

      @media (max-width: 765px) {
        left: 10%;
      }

      @media (max-width: 610px) {
        left: 0;
      }

      @media (max-width: 550px) {
        top: 35%;
      }

      @media (max-width: 470px) {
        left: -5%;
      }

      @media (max-width: 430px) {
        left: -10%;
      }

      @media (max-width: 400px) {
        top: 40%;
        left: -15%;
      }

      @media (max-width: 365px) {
        top: 35%;
        left: -20%;
      }
    }
  }
}
