.Privacy {
  max-width: 100%;
  position: relative;
  height: 100vh;
  font-family: "Times New Roman", Times, serif;

  .privacy__content {
    width: 95%;
    padding: 10px;
    font-size: 14px;

    .title {
      font-weight: bold;
      text-align: center;
      font-size: 14px;
    }

    .date {
      text-align: center;
      font-size: 14px;
    }

    p {
      font-size: 14px;
      font-family: "Times New Roman", Times, serif;
      line-height: 1.5;
    }

    ol {
      list-style-type: decimal;
      font-size: 14px;
    }
  }

  .privacy__footer {
    width: 100%;
    // position: absolute;
    // bottom: 0;
    // left: 0;
  }
}
