.top10 {
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px;
  box-sizing: border-box;

  @media (max-width: 890px) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.top10__users {
  width: 300px;
  text-align: center;
  background-color: #c7e6d0;
  border-radius: 30px;
  padding-bottom: 15px;
  margin-top: 20px;

  @media (max-width: 890px) {
    width: 400px;
  }

  @media (max-width: 420px) {
    width: 320px;
  }
}

.top10__users h2 {
  font-size: 40px;
  padding-bottom: 10px;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  text-align: center;
  color: white;
  padding-top: 15px;
}

.top10__users-list {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
}
.user__item {
  display: flex;
  padding-top: 10px;
}

.user__item-avatar img {
  max-width: 50px;
}

.user__item-insta {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  padding-top: 15px;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.top10__books {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

  .slider {
    width: 750px;

    @media (max-width: 1101px) {
      width: 500px;
      margin: 0 auto;
    }

    @media (max-width: 577px) {
      width: 280px;
    }

    @media (max-width: 875px) {
    }
  }
}

.top__books-title {
  font-size: 60px;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  text-align: center;
  color: #5bc1b3;
  text-transform: uppercase;

  @media (max-width: 1101px) {
    font-size: 50px;
  }

  @media (max-width: 992px) {
    font-size: 45px;
    padding-top: 10px;
  }

  @media (max-width: 875px) {
    margin: 0 auto;
  }

  @media (max-width: 599px) {
    font-size: 35px;
  }
}
