.compareCard {
  height: 110px;
  margin: 10px 10px;
  width: 200px;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: space-around;
  flex-direction: column;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  position: relative;

  @media (max-width: 920px) {
    width: 170px;
  }

  @media (max-width: 650px) {
    width: 140px;
  }

  @media (max-width: 499px) {
    width: 110px;
    margin: 5px 5px;
  }

  @media (max-width: 375px) {
    width: 95px;
  }

  .title {
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    line-height: 1;
    font-weight: 400;

    @media (max-width: 920px) {
      font-size: 16px;
    }
  }

  .count {
    font-size: 35px;
    font-family: 'Times New Roman', Times, serif;


   @media (max-width: 375px) {
      font-size: 33px;
    }

   @media (max-width: 375px) {
      font-size: 25px;
    }
  }

  .icon{
   position: absolute;
   right: 0;

   @media (max-width: 560px) {
    display: none;
  }
  }
}
