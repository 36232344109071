.Data {
  width: 95%;
  background: gray;
  padding: 15px;
  border-radius: 10px;
  font-size: 15px;
  margin: 10px auto;

  .all_forms {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      margin: 5px;
      background: #a9dfd8;
      border-radius: 10px;
      padding: 5px;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
      }

      input {
        padding: 3px;
        padding-left: 5px;
        border-radius: 5px;
        border: 1px solid white;
        margin-top: 5px;
        margin-left: 5px;
      }

      .form__title {
        color: white;
        font-size: 16px;
        font-weight: bold;
      }

      div {
        .this_read_input {
          width: 95%;
          height: 100px;
          text-align: left;
        }
      }
    }
  }

  .all_forms2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    flex-direction: column;
    align-items: center;

    .first_forms_block {
      max-width: 1050px;
    }
  }
}
