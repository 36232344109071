.RegUsersList {
  width: 100%;
  height: 100vh;
  border: 1px solid black;
  box-sizing: border-box;

  .main {
    width: 100%;
    display: flex;

    .slider {
      flex: 1;
    }

    .content {
      flex: 5;
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        margin-top: 20px;
        height: 50px;
      }

      .reg__users__table {
        width: 95%;

        .table {
          font-size: 14px;
          padding: 5px;
        }
      }
    }
  }
}
