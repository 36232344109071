.article__page {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 10px;
  align-items: flex-start;

  .articles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    flex: 5;

    .item {
      width: 33%;
      @media (max-width: 1050px) {
        width: 33%;
      }

      @media (max-width: 992px) {
        width: 50%;
      }

      @media (max-width: 550px) {
        width: 70%;
      }

      @media (max-width: 450px) {
        width: 95%;
      }
    }
  }

  .info__panel {
    flex: 2;
    width: 100%;
    padding-top: 10px;
    @media (max-width: 650px) {
      display: none;
    }
    display: flex;
    flex-direction: column;
    align-items: center;

    .search {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 5px;
      border: 1px solid rgb(200, 199, 199);

      .icon {
        background: #5bc1b3;
        color: white;
        height: 100%;
        position: absolute;
        border-radius: 5px 0 0 5px;
      }

      input {
        padding: 5px 5px 5px 30px;
        width: 100%;
        outline: none;
        border: 0;
        border-radius: 5px;
      }
    }

    .categories {
      width: 100%;
      border: 1px solid rgb(200, 199, 199);
      display: flex;
      flex-direction: column;
      padding: 5px;
      margin-top: 20px;
      border-radius: 10px;

      .title {
        font-size: 20px;
        padding-left: 5px;
      }

      .categories__list {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        .item {
          padding: 5px;
          border: 1px solid rgb(200, 199, 199);
          margin: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            cursor: pointer;
            background: #fa8e46;
            color: white;
          }

          div {
            display: flex;

            span {
              padding-left: 5px;
            }
          }
        }

        .active {
          background: #5bc1b3;
          color: white;
          font-weight: bold;
        }
      }
    }

    .banner_mr {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      border-radius: 10px;
    }

    .popular__posts {
      width: 100%;
      border-radius: 10px;
      border: 1px solid rgb(200, 199, 199);
      display: flex;
      flex-direction: column;
      padding: 5px;
      margin-top: 20px;

      .title {
        font-size: 20px;
        padding-left: 5px;
      }

      .posts {
        margin: 0;
        padding: 0;

        .item {
          margin: 5px 0;
          padding: 10px 5px;

          &:hover {
            cursor: pointer;
            background: #c7e6d0;
            color: white;
          }
        }
      }
    }

    .advertising {
      width: 100%;
      border-radius: 10px;
      border: 1px solid rgb(200, 199, 199);
      display: flex;
      flex-direction: column;
      padding: 5px;
      margin-top: 20px;
      text-align: center;
      align-items: center;

      .first_advertising_block {
        width: 200px;
        height: 200px;
      }
    }
  }
}
