
.chartPage {
  text-align: center;
  padding: 5px;
  .title {
    font-size: 30px;
    font-family: Oswald, sans-serif;
    color: #5bc1b3;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 10px;

    @media (max-width: 499px) {
      font-size: 23px;
    }
  }
}
