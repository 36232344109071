* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
}

main {
  display: block;
}

.container {
  padding: 0 15px;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
}

/*section FAQ начало*/
.faq {
  padding-top: 50px;
}

.accordion {
  width: 90%;
  max-width: 1000px;
  margin: 2rem auto;
}

.accordion-item {
  background-color: #fff;
  color: #111;
  margin: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  padding-top: 10px;
  padding-bottom: 10px;
}

.accordion-item-header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 22px;
}

.accordion-item-header::after {
  content: '\002B';
  font-size: 2rem;
  position: absolute;
  right: 1rem;
}

.accordion-item-header.active::after {
  content: '\2212';
}

.accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-item-body-active {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 1px solid;
  border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
  font-size: 20px;
  font-family: 'Alumni Sans', sans-serif;
  font-weight: 400;
}

.accordion-item-body-content a {
  text-decoration: none;
  font-weight: bold;
}

.faq__btn {
  padding-top: 10px;
}
