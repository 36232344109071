.dashboard {
  width: 100%;
  height: 100vh;
  border: 1px solid black;
  box-sizing: border-box;

  .main {
    width: 100%;
    display: flex;

    .slider {
      flex: 1;
    }

    .content {
      flex: 5;
    }
  }
}
