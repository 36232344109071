.swiper {
  .swiper-slide-prev {
    border: none;
    display: flex;
    border-radius: 20px;
    flex-direction: row-reverse;
    opacity: 0.7;
    box-shadow: none;
  }

  .swiper-slide-next {
    border: none;
    opacity: 0.7;
    box-shadow: none;
  }

  .swiper-pagination {
    position: absolute;

    @media (max-width: 530px) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    width: 15px;
    height: 5px;
    border-radius: 5px;
    margin-top: 50px;
  }
}

.BooksPerYear {
  .title_block {
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    color: #5bc1b3;
    text-transform: uppercase;
    font-size: 60px;

    @media (max-width: 800px) {
      font-size: 45px;
    }

    @media (max-width: 530px) {
      font-size: 30px;
    }

    @media (max-width: 430px) {
      font-size: 25px;
    }
  }

  .book_slider {
    max-width: 1150px;
    width: 90%;
    padding: 10px;
    position: relative;

    .swiper-slide {
      width: 500px;
      border-radius: 20px;
      display: flex;
      box-sizing: border-box;
      overflow: hidden;
      background: #d3dfeb;

      @media (max-width: 800px) {
        width: 400px;
      }

      @media (max-width: 530px) {
        width: 300px;
      }

      .slide_image {
        width: 200px;
        height: 300px;
        border-radius: 25px;
        padding: 15px;
        position: relative;

        @media (max-width: 530px) {
          border-radius: 15px;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      font-weight: bold;

      @media (max-width: 430px) {
        display: none;
      }
    }

    .swiper-pagination {
      position: absolute;
      bottom: -5px;
    }

    .book {
      position: relative;
      box-sizing: border-box;

      .image {
        width: 200px;
        height: 300px;
        border-radius: 25px;
        padding: 15px;
        object-fit: cover;

        @media (max-width: 800px) {
          width: 160px;
          height: 240px;
        }

        @media (max-width: 530px) {
          width: 120px;
          height: 180px;
          padding: 5px;
          border-radius: 15px;
        }
      }

      .publishingHouse {
        position: absolute;
        bottom: -100px;
        left: 15px;
        background-color: rgb(105, 25, 25);
        width: 170px;
        height: 40px;
        text-align: center;
        padding-top: 3px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        color: white;
        font-weight: bold;
        transition: 0.5s;

        @media (max-width: 800px) {
          width: 130px;
        }

        @media (max-width: 530px) {
          height: 35px;
          width: 110px;
          font-size: 12px;
        }
      }

      .number {
        position: absolute;
        top: -200px;
        left: 20%;
        text-align: center;
        padding-top: 3px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        color: #1b6158;
        font-weight: bold;
        font-size: 100px;

        @media (max-width: 800px) {
          font-size: 80px;
        }

        @media (max-width: 530px) {
          font-size: 60px;
        }
      }

      .last_number {
        font-size: 80px;

        @media (max-width: 800px) {
          font-size: 65px;
        }

        @media (max-width: 530px) {
          font-size: 45px;
        }
      }

      &:active,
      &:hover,
      &:focus {
        .image {
          opacity: 0.5;
        }

        .publishingHouse {
          display: block;
          bottom: 15px;
          left: 15px;
          z-index: 100;

          @media (max-width: 530px) {
            left: 5px;
            bottom: 5px;
          }
        }

        .number {
          top: 10%;
          left: 20%;
          display: block;
          opacity: 1;
          z-index: 100;
        }

        .last_number {
          top: 15%;
          left: 15%;
        }
      }
    }

    .text {
      margin: 15px 15px 15px 0;
      padding: 5px;
      padding-top: 10px;
      border-radius: 15px;
      box-sizing: border-box;
      background: white;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (max-width: 530px) {
        margin: 5px 5px 5px 0;
      }

      .desc {
        position: relative;
        .head {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding-bottom: 10px;

          @media (max-width: 800px) {
            padding-bottom: 5px;
          }

          .name {
            font-size: 20px;
            font-weight: bold;
            flex: 4;
            line-height: 1;

            @media (max-width: 800px) {
              font-size: 16px;
            }

            @media (max-width: 530px) {
              font-size: 14px;
            }
          }

          .genre {
            flex: 2;
            border-radius: 10px;
            background: #5bc1b3;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            color: white;
            font-size: 14px;
            font-weight: bold;

            @media (max-width: 800px) {
              font-size: 12px;
              padding-left: 3px;
            }
          }
        }

        .info {
          width: 100%;
          height: 100px;
          padding: 5px;
          overflow: auto;
          line-height: 1.1;

          @media (max-width: 800px) {
            line-height: 1;
            height: 80px;
            font-size: 14px;
          }

          @media (max-width: 530px) {
            display: none;
          }
        }

        .rating {
          position: absolute;
          top: 150px;
          display: flex;
          flex-direction: column;
          justify-content: left;

          @media (max-width: 800px) {
            top: 120px;
          }

          @media (max-width: 530px) {
            top: 55px;
          }

          .title {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 10px;
            padding-left: 5px;

            @media (max-width: 800px) {
              font-size: 14px;
            }
          }

          .icon {
            text-align: center;
            font-size: 22px;
            margin: -15px 5px;

            @media (max-width: 800px) {
              font-size: 19px;
            }
          }
        }
      }

      .btn1 {
        position: absolute;
        bottom: 15px;
        left: 18%;

        @media (max-width: 800px) {
          bottom: 10px;
          left: 23%;
        }

        @media (max-width: 530px) {
          bottom: 8px;
          left: 14%;
        }

        button {
          border: none;
          text-transform: uppercase;

          border-radius: 10px;
          background: #5bc1b3;
          font-weight: bold;
          padding: 5px 15px;

          a {
            text-decoration: none;
            color: white;
          }

          @media (max-width: 800px) {
            padding: 0px 10px;
            font-size: 12px;
          }

          &:hover {
            background: #1b6158;
          }
        }
      }
    }
  }
}
