.button {
  border: none;
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.primary {
  background-color: #13a463;
  color: white;

  &:hover {
    background-color: #13a463dc;
  }

  &:active {
    background-color: #0c5d38;
  }
}

.default {
  background-color: #ffffff;
  color: black;
  border: 1px solid black;
  transition: border 0.2s;

  &:hover {
    border: 1px solid gray;
  }

  &:active {
    border: 1px solid #b4b4b4;
  }
}
