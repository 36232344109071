.preview {
  position: relative;
  padding: 10px 0;
  margin: 5px;
  display: flex;
  flex-direction: column;

  &:hover {
    opacity: 0.9;
    background: whitesmoke;
    border-radius: 5px;
  }

  .badge {
    position: absolute;
    top: 2px;
    left: 2px;
    padding: 4px 8px;
    border-radius: 20px;
    background-color: #00afff;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 11px;
  }
  .delete {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    font-size: 20px;

    &:hover {
      color: red;
    }
  }
  .wrapperImage {
    text-align: center;
    .image {
      width: 150px;
      height: 200px;
      object-fit: contain;
    }
  }
  .title {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 14px;
  }
}
