* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  font-weight: 300;
  font-size: 16px;
}

main {
  display: block;
}

.container {
  padding: 0 15px;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
}

.metablock {
  display: none;
}
