.scroll {
  opacity: 0.4;
  @media (max-width: 993px) {
    display: none;
  }
}

.pencil {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 40px;
    height: 40px;
  }
}
