.Upload {
  background: goldenrod;
  border-radius: 5px;
  margin: 20px;
  padding: 10px;
  text-align: center;
  min-height: 100px;
  margin-right: 20px;

  .buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;

    .input {
      display: none;
    }
  }

  .imagesWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
