.loadingUserPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  img {
    @media (max-width: 450px) {
      width: 300px;
    }
  }

  p {
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    color: green;

    @media (max-width: 450px) {
      font-size: 15px;
    }
  }
}
