.button {
  display: inline-block;
  background: #fa8e46;
  padding: 1rem 1.5rem;
  text-decoration: none;
  border-radius: 10px;
  font-weight: bold;
  border: none;
  margin-bottom: 10px;

  &:hover {
    background: #c7e6d0;
    color: white;
    cursor: pointer;
    font-weight: bold;
    border: none;
  }
}
