.review {
  width: 100%;
  height: 250px;
  background: #c7e6d0;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 580px) {
    height: 500px;
    margin: 0px auto;
    padding: 5px;
  }

  .advertising {
    width: 700px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 580px) {
      margin-left: 0;
      margin-bottom: 15px;
      flex-direction: column;
      text-align: center;
    }
  }
}
