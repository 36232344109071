.article {
  .head {
    width: 100%;
    height: 400px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      filter: brightness(50%);
    }

    .title {
      position: absolute;
      margin: 0 auto;
      color: white;
      text-align: center;
      font-size: 30px;
      width: 100%;
      padding: 0 50px;
      top: 40%;

      @media (max-width: 650px) {
        top: 30%;
        font-size: 28px;
      }

      @media (max-width: 576px) {
        top: 25%;
        font-size: 23px;
      }

      .desc {
        font-size: 18px;
        width: 35%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .time,
        .chapter,
        .views {
          flex: 1;
          padding: 0 5px;
        }

        .chapter {
          border-left: 2px solid white;
          border-right: 2px solid white;
        }

        .views {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            padding: 5px;
          }
        }
      }
    }
  }

  .text {
    width: 95%;
    padding: 10px;
    border-bottom: 1px solid rgb(179, 169, 169);
    margin: 10px auto;
    box-sizing: border-box;
    max-width: 1200px;
    line-height: 1.875em;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 20px;

    p {
      max-width: 950px;
      width: 100%;
      margin: 0 auto;
      padding-left: 10px;
      display: flex;

      img {
        width: 95%;
        padding-top: 5px;
        justify-content: center;
      }
      a {
        color: black;
        &:hover {
          cursor: pointer;
          color: gray;
        }
      }
    }

    h1 {
      text-align: center;
      padding: 15px 0;
      margin: 0;

      @media (max-width: 699px) {
        font-size: 18px;
      }
      a {
        color: black;

        &:hover {
          cursor: pointer;
          color: gray;
        }

        @media (max-width: 699px) {
          font-size: 18px;
        }
      }
    }

    ul {
      list-style: disc;
      text-align: left;
    }

    ol {
      list-style-type: none;
      counter-reset: num;
      margin: 0 0 0 45px;
      padding: 15px 0 5px 0;
      font-size: 16px;
      width: 95%;
      padding: 0 10px;

      li {
        position: relative;
        margin: 0 0 0 0;
        padding: 0 0 10px 0;
        padding-bottom: 20px;
        line-height: 1.4;
        width: 95%;

        &:before {
          content: counter(num);
          counter-increment: num;
          display: inline-block;
          position: absolute;
          top: 0;
          left: -40px;
          width: 40px;
          height: 40px;
          background: #c7e6d0;
          color: #fff;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          font-weight: 600;
          font-family: Oswald, sans-serif;
        }

        p {
          margin: 0;
          text-align: left;
          min-width: 100%;
          padding: 0 10px;
        }
      }
    }

    blockquote {
      margin: 0;
      background: #f7f7f7;
      padding: 40px;
      color: #3f484d;
      position: relative;
      font-family: "Lato", sans-serif;
      text-align: center;
      margin-bottom: 20px;

      &:before,
      &:after {
        font-size: 45px;
        color: #5bc1b3;
        position: absolute;
        height: 2px;
        left: 40px;
        right: 40px;
        line-height: 0.5;
        background: linear-gradient(
            to right,
            #5bc1b3 45%,
            transparent 45%,
            transparent
          ),
          linear-gradient(to right, transparent, transparent 55%, #5bc1b3 55%);
        font-family: serif;
      }

      &:before {
        content: "\201C";
        top: 30px;
      }

      &:after {
        content: "\201D";
        bottom: 30px;
      }
      p {
        font-size: 20px;

        @media (max-width: 899px) {
          font-size: 18px;
        }

        @media (max-width: 499px) {
          font-size: 16px;
        }
      }
    }
  }

  .other__articles {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;

    .item {
      width: 33%;

      @media (max-width: 699px) {
        width: 50%;

        &:last-child {
          display: none;
        }
      }
      @media (max-width: 420px) {
        width: 95%;
      }
    }
  }

  .article__advertising {
    width: 90%;
    max-width: 700px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-top: 20px;
    text-align: center;
    align-items: center;
    margin: 0 auto;
    justify-content: space-around;

    @media (max-width: 699px) {
      flex-direction: column;
    }
  }

  .banner__mr__big {
    width: 90%;
    max-width: 700px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    text-align: center;
    align-items: center;
    margin: 0 auto;
    justify-content: space-around;
    margin-bottom: 12px;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .banner__mr__small {
    width: 90%;
    max-width: 700px;
    border-radius: 10px;
    display: flex;
    margin-top: 20px;
    text-align: center;
    margin: 0 auto;

    @media (min-width: 650px) {
      display: none;
    }
  }
}
