@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;1,300&display=swap");

.UserMonthStatistics {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  max-width: 1200px;
  margin: 0 auto;

  .title_User_Page {
    font-size: 35px;
    font-family: Oswald, sans-serif;
    font-weight: 800;
    color: #5bc1b3;
    text-transform: uppercase;
    text-align: center;
    padding: 0 5px;

    @media (max-width: 580px) {
      font-size: 30px;
    }
  }

  .cards {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    div {
      display: flex;
    }
  }

  .charts {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    width: 95%;
    border-radius: 10px;

    .statistics {
      display: flex;
      align-items: center;
      padding: 5px;
      justify-content: center;
      background: none;

      @media (max-width: 850px) {
        flex-direction: column;
      }

      .statistics_chart {
        width: 65%;

        .title {
          text-align: center;
          font-family: Oswald, sans-serif;
          font-weight: 700;
          color: #5bc1b3;
          text-transform: uppercase;
          padding-top: 10px;
          font-size: 27px;
        }

        @media (max-width: 850px) {
          width: 100%;
          margin: 0 auto;
        }
      }

      .statistics_pie {
        width: 35%;
        margin-top: -30px;

        .title {
          text-align: center;
          font-family: Oswald, sans-serif;
          font-weight: 700;
          color: #5bc1b3;
          text-transform: uppercase;
          padding-top: 10px;
          font-size: 27px;
        }

        @media (max-width: 850px) {
          width: 100%;
          margin-top: 0;
          padding-bottom: 10px;
        }

        @media (max-width: 550px) {
          margin-top: 20px;
        }

        @media (max-width: 430px) {
          width: 70%;
        }
      }
    }
  }

  .medals {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    width: 95%;
    margin-top: 20px;
    border-radius: 10px;
    padding: 5px;
  }

  .user_footer {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    width: 95%;
    margin-top: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 5px;
    display: flex;

    .quote {
      flex: 4;
      margin: 0;
      padding: 0;
      font-weight: 300;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      blockquote {
        margin: 0;
        padding-top: 10px;
      }

      blockquote p {
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 25px;
        font-family: "Times New Roman", Times, serif;
        text-align: center;
        line-height: 1;

        @media (max-width: 699px) {
          font-size: 20px;
        }

        @media (max-width: 599px) {
          font-size: 18px;
        }

        @media (max-width: 465px) {
          font-size: 15px;
        }
      }

      blockquote p::before {
        content: "\201C";
        padding: 5px;
      }

      blockquote p::after {
        content: "\201D";
        padding: 5px;
      }
    }

    .blockBack {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 450px) {
        flex: 3;
      }

      .img {
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
