.home {
  margin: 0 auto;
  .hidenBlock {
    display: none;
  }

  .loadingPicture {
    text-align: center;
    border: 1px solid black;
  }
}

