$button-width: 250px;

.modal__login {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  .modal__content {
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    height: 300px;
    width: 400px;
    transform: scale(0.5);
    transition: 0.4s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .google-auth-button {
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      height: 36px;
      width: $button-width;
      padding: 0 12px;
      border-radius: 4px;
      border: 1px solid #4285f4;
      background-color: #4285f4;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.2px;
      line-height: 34px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      margin-bottom: 20px;

      img {
        position: absolute;
        left: 8px;
        top: 8px;
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: #3b78e7;
        border-color: #3b78e7;
      }

      &:active {
        background-color: #3461c1;
        border-color: #3461c1;
      }
    }

    .privacy {
      color: gray;
      text-align: center;
      padding: 10px 30px;
      font-size: 11px;

      .privacy__link {
        color: gray;
      }
    }

    .modal__exit {
      border: none;
      margin-top: 10px;
      background: #fa8e46;
      color: white;
      border-radius: 10px;
      padding: 5px 10px;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .modal__content.active {
    transform: scale(1);
  }
}

.modal__login.active {
  opacity: 1;
  pointer-events: all;
}
