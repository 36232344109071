html[data-theme="dark"] {
  --background: red;
}

html[data-theme="light"] {
  --background: white;
}

.profile {
  .profile__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      margin-top: 25%;
      margin-bottom: 20px;
      .icon {
        color: red;
      }
    }
  }
}
