.icons {
  display: flex;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
}

.icons {
  .icon {
    margin: 0 10px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    @media (max-width: 576px) {
      margin: 5px 5px;
    }

    &:hover {
      .toolrip {
        top: -60px;
        opacity: 1;
        pointer-events: auto;
        text-align: center;
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
      }

      span {
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
      }
    }

    span {
      position: relative;
      z-index: 2;
      width: 50px;
      height: 50px;
      display: block;
      background: #fff;
      text-align: center;
      border-radius: 50%;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);

      @media (max-width: 440px) {
        width: 35px;
        height: 35px;
      }

      i {
        font-size: 25px;
        line-height: 52px;

        @media (max-width: 440px) {
          font-size: 20px;
          line-height: 37px;
        }

        &:hover {
          color: white;
        }
      }
    }

    .toolrip {
      position: absolute;
      top: 0;
      background: #fff;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      padding: 10px 18px;
      border-radius: 25px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

      &:hover {
      }
    }

    a {
      color: black;

      &:visited {
        color: inherit;
      }
    }
  }

  .telegram:hover span,
  .telegram:hover .toolrip,
  .telegram:hover .toolrip:before {
    background: #8abff4;
    color: white;
    font-weight: bold;
  }

  .instagram:hover span,
  .instagram:hover .toolrip,
  .instagram:hover .toolrip:before {
    background: #de3d8d;
    color: white;
    font-weight: bold;
  }

  .youtube:hover span,
  .youtube:hover .toolrip,
  .youtube:hover .toolrip:before {
    background: #f41515;
    color: white;
    font-weight: bold;
  }

  .bot:hover span,
  .bot:hover .toolrip,
  .bot:hover .toolrip:before {
    background: #e1dca0;
    color: white;
    font-weight: bold;
  }

  .patreon:hover span,
  .patreon:hover .toolrip,
  .patreon:hover .toolrip:before {
    background: #ff5900;
    color: white;
    font-weight: bold;
  }

  .facebook:hover span,
  .facebook:hover .toolrip,
  .facebook:hover .toolrip:before {
    background: #4867aa;
    color: white;
    font-weight: bold;
  }
}
