.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 150px;
  place-items: center;
  background: linear-gradient(315deg, #ffffff, #d7e1ec);
  padding-top: 20px;
  box-sizing: border-box;
}

.footer__info {
  text-align: center;
  line-height: 0;
  font-weight: 500;
  padding-top: 10px;
}

.footer__info {
  font-family: "Times New Roman", Times, serif;
  div p a {
    text-decoration: none;
    color: black;
  }

  .privacy {
    color: black;
    font-size: 14px;
  }
}

@media (max-width: 440px) {
  .footer__info {
    text-align: center;
  }

  .footer__info div p {
    font-size: 14px;
  }
}
