.chartBooks {
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border: 1px solid black;

  @media (max-width: 850px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @media (max-width: 740px) {
    margin-bottom: 0;
  }

  @media (max-width: 550px) {
    margin-bottom: -20px;
  }
}
