.medalsInfo {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  .title {
    padding-top: 10px;
    text-align: center;
    font-family: Oswald, sans-serif;
    font-weight: 700;
    color: #5bc1b3;
    text-transform: uppercase;
    padding-top: 10px;
    font-size: 27px;
  }

  .medalItems {
    width: 100%;
    display: flex;
    .items {
      width: 25%;
      align-items: center;
      justify-content: space-around;
    }
  }
}
