.regSteps {
  display: flex;
  align-items: center;
  background-color: #5bc1b3;
  justify-content: space-between;
  overflow: hidden;

  .picture {
    img {
      @media (max-width: 975px) {
        display: none;
      }
    }
  }

  .text {
    flex: 10;
    padding-left: 50px;
    padding-right: 150px;

    @media (max-width: 1440px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 1220px) {
      padding-right: 0;
    }

    @media (max-width: 950px) {
      padding-left: 10px;
    }

    .title {
      h2 {
        color: #008377;
        font-family: Oswald, sans-serif;
        font-size: 50px;
        font-weight: 700;
        padding-bottom: 20px;
        text-align: left;

        @media (max-width: 1200px) {
          font-size: 35px;
          font-weight: 600;
        }

        @media (max-width: 975px) {
          text-align: center;
          padding-top: 15px;
        }

        @media (max-width: 420px) {
          font-size: 25px;
        }
      }
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .item {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 0 50px 50px 0;

        @media (max-width: 1200px) {
          padding: 0 15px 15px 0;
        }

        @media (max-width: 975px) {
          text-align: center;
        }

        @media (max-width: 499px) {
          width: 100%;
          padding: 0 30px 30px 0;
        }

        .item__title {
          color: #fff;
          font-family: Oswald, sans-serif;
          font-size: 50px;
          font-weight: 700;
          padding-bottom: 10px;

          @media (max-width: 1200px) {
            font-size: 35px;
            font-weight: 600;
          }

          @media (max-width: 420px) {
            font-size: 25px;
          }
        }

        .item__text {
          color: #fdf7f9;
          font-family: 'Times New Roman', Times, serif;
          font-size: 18px;
          line-height: 1.1;

          // @media (max-width: 1200px) {
          //   font-size: 20px;
          // }
        }
      }
    }
  }
}
