.registration {
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 799px) {
    flex-direction: column-reverse;
  }

  .reg__text {
    padding-left: 150px;

    @media (max-width: 992px) {
      padding-left: 50px;
    }

    @media (max-width: 799px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .reg__text-title {
      padding-bottom: 20px;
      font-weight: bold;
      padding-top: 20px;

      @media (max-width: 992px) {
        text-align: center;
      }
    }

    .reg__text-text {
      h2 {
        padding-bottom: 15px;
        font-size: 18px;
        font-family: 'Bitter', serif;

        @media (max-width: 1130px) {
          font-size: 16px;
        }
      }
    }

    .reg__text-button {
      .btn {
        display: inline-block;
        background: #fa8e46;
        color: white;
        padding: 1rem 1.5rem;
        text-decoration: none;
        border-radius: 10px;
        font-weight: bold;
        border: none;
        margin-bottom: 10px;
      }
      .btn a {
        text-decoration: none;
      }

      .btn:hover {
        background: #c7e6d0;
        color: #fff;
        cursor: pointer;
        font-weight: bold;
        border: none;
      }

      @media (max-width: 992px) {
        text-align: center;
        padding-bottom: 15px;
      }
    }
  }

  .reg__photo {
    img {
      max-height: 400px;

      @media (max-width: 992px) {
        max-height: 300px;
      }

      @media (max-width: 799px) {
        max-height: 250px;
      }

      @media (max-width: 350px) {
        max-height: 220px;
        padding-left: 40px;
        padding-right: 0;
      }
    }
  }
}
