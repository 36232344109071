.faqBlock {
  display: flex;
  width: 100%;
  z-index: 1;

  @media (max-width: 890px) {
    flex-direction: column-reverse;
    margin: 0 auto;
    text-align: center;
  }
  .accordion {
    width: 70%;
    max-width: 1000px;
    margin: 2rem auto;

    @media (max-width: 1100px) {
      max-width: 700px;
    }

    @media (max-width: 890px) {
      width: 100%;
      margin: 0 auto;
    }

    .title {
      font-size: 55px;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;

      color: #5bc1b3;
      padding-bottom: 20px;
      text-transform: uppercase;

      @media (max-width: 651px) {
        width: 100%;
        font-size: 35px;
      }
    }

    .btn {
      display: inline-block;
      background: #fa8e46;
      color: white;
      padding: 1rem 1.5rem;
      text-decoration: none;
      border-radius: 10px;
      font-weight: bold;
      border: none;
      margin-bottom: 10px;

      &:hover {
        background: #c7e6d0;
        color: #fff;
        cursor: pointer;
        font-weight: bold;
        border: none;
      }
    }
  }

  .img {
    img {
      max-width: 350px;
      margin-left: 10px;

      @media (max-width: 992px) {
        max-width: 250px;
        padding-top: 50px;
      }

      @media (max-width: 576px) {
        max-width: 200px;
        padding-top: 30px;
      }

      @media (max-width: 420px) {
        max-width: 150px;
        padding-top: 20px;
      }
    }
  }
}
