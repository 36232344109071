.singleArticles_block {
  display: flex;
  width: 100%;

  .singleArticle {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    border-radius: 10px;
    -webkit-box-shadow: -1px 6px 12px -3px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: -1px 6px 12px -3px rgba(0, 0, 0, 0.24);
    box-shadow: -1px 6px 12px -3px rgba(0, 0, 0, 0.24);

    .image__conteiner {
      min-height: 150px;
      height: 100%;
      overflow: hidden;
      border-radius: 10px 10px 0 0;

      @media (max-width: 535px) {
        min-height: 100px;
      }

      .image {
        width: 100%;
        height: 200px;
        border-radius: 10px 10px 0 0;
        margin-bottom: 10px;
        object-fit: cover;
        transition: 0.7s ease all;
        overflow: hidden;

        &:hover {
          transform: scale(1.5);
        }
      }
    }

    .info {
      padding: 7px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 120px;

      .title {
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.25rem;
        text-align: center;

        height: 70px;
        overflow: hidden;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(to bottom, #000, #000, rgba(0, 0, 0, 0));

        &:hover {
          color: #fa8e46;
          cursor: pointer;
        }

        @media (max-width: 799px) {
          font-size: 17px;
          line-height: 1.1rem;
          font-weight: 500;
        }

        @media (max-width: 470px) {
          font-size: 14px;
          font-weight: 400;
        }
        @media (max-width: 420px) {
          font-size: 15px;
          font-weight: 500;
        }
      }

      .info__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .chapter {
          align-self: flex-start;
          color: #5bc1b3;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 13px;

          @media (max-width: 420px) {
            line-height: 0.5;
          }
        }

        .info__time__and__button {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            opacity: 0.6;

            @media (max-width: 535px) {
              font-size: 13px;
            }

            @media (max-width: 470px) {
              font-size: 12px;
            }
          }

          button {
            padding: 3px 10px;
            border-radius: 10px;
            background: #fa8e46;
            border: none;
            color: white;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 15px;

            @media (max-width: 535px) {
              font-size: 13px;
            }

            @media (max-width: 470px) {
              font-size: 12px;
              padding: 3px 5px;
            }

            &:hover {
              background: #c7e6d0;
            }
          }
        }
      }
    }
  }
}
