.articleBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  margin: 0 auto;
  padding: 10px 10px;

  .articlesWrapper {
    width: 100%;
    max-width: 1200px;

    .head {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      align-items: center;

      .title {
        text-transform: uppercase;
        font-weight: 700;
        color: #5bc1b3;
        font-family: Oswald, sans-serif;
        font-size: 55px;

        @media (max-width: 650px) {
          font-size: 35px;
        }

        @media (max-width: 499px) {
          font-size: 30px;
        }

        @media (max-width: 420px) {
          font-size: 22px;
        }
      }

      button {
        padding: 5px 15px;
        border-radius: 10px;
        background: #fa8e46;
        border: none;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 17px;

        @media (max-width: 499px) {
          font-size: 16px;
          padding: 5px 10px;
        }

        @media (max-width: 399px) {
          font-size: 16px;
          padding: 5px;
        }

        &:hover {
          background: #c7e6d0;
        }
      }
    }

    .articles {
      width: 100%;
      display: flex;
      height: 100%;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        width: 33%;

        @media (max-width: 699px) {
          width: 50%;

          &:last-child {
            display: none;
          }
        }
        @media (max-width: 420px) {
          width: 95%;
        }
      }
    }
  }
}
