.userCard {
  height: 100px;
  margin: 10px 10px;
  width: 200px;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: space-around;
  flex-direction: column;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

  @media (max-width: 920px) {
    width: 160px;
    height: 100px;
  }

  @media (max-width: 750px) {
    height: 100px;
    width: 200px;
  }

  @media (max-width: 460px) {
    width: 160px;
    height: 80px;
  }

  @media (max-width: 375px) {
    width: 145px;
    height: 75px;
  }

  .title {
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;

    @media (max-width: 920px) {
      font-size: 16px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
    }
  }

  .count {
    font-size: 35px;
    font-family: 'Times New Roman', Times, serif;
  }
}
