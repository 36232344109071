.AddArticle {
  width: 100%;
  min-height: 100vh;

  .main {
    width: 100%;
    display: flex;

    .sidebar {
      flex: 1;
    }

    .content {
      flex: 5;
      padding: 5px;

      form {
        display: flex;
      }

      .select {
        display: block;
        width: 50%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.375rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

      .text {
        width: 95%;
        padding: 10px;
        border-bottom: 1px solid rgb(179, 169, 169);
        margin: 10px auto;
        box-sizing: border-box;
        max-width: 1200px;
        line-height: 1.875em;
        font-family: Arial, Helvetica, sans-serif;
        padding-bottom: 20px;

        p {
          max-width: 950px;
          width: 100%;
          margin: 0 auto;
          padding-left: 10px;
          display: flex;

          img {
            width: 95%;
            padding-top: 5px;
            justify-content: center;
          }
          a {
            color: black;
            &:hover {
              cursor: pointer;
              color: gray;
            }
          }
        }

        h1 {
          text-align: center;
          padding: 15px 0;
          margin: 0;

          @media (max-width: 699px) {
            font-size: 18px;
          }
          a {
            color: black;

            &:hover {
              cursor: pointer;
              color: gray;
            }

            @media (max-width: 699px) {
              font-size: 18px;
            }
          }
        }

        ul {
          list-style: disc;
          text-align: left;
        }

        ol {
          list-style-type: none;
          counter-reset: num;
          margin: 0 0 0 45px;
          padding: 15px 0 5px 0;
          font-size: 16px;
          width: 95%;
          padding: 0 10px;

          li {
            position: relative;
            margin: 0 0 0 0;
            padding: 0 0 10px 0;
            padding-bottom: 20px;
            line-height: 1.4;
            width: 95%;

            &:before {
              content: counter(num);
              counter-increment: num;
              display: inline-block;
              position: absolute;
              top: 0;
              left: -40px;
              width: 40px;
              height: 40px;
              background: #c7e6d0;
              color: #fff;
              text-align: center;
              line-height: 40px;
              font-size: 18px;
              font-weight: 600;
              font-family: Oswald, sans-serif;
            }

            p {
              margin: 0;
              text-align: left;
              min-width: 100%;
              padding: 0 10px;
            }
          }
        }

        blockquote {
          margin: 0;
          background: #f7f7f7;
          padding: 40px;
          color: #3f484d;
          position: relative;
          font-family: 'Lato', sans-serif;
          text-align: center;
          margin-bottom: 20px;

          &:before,
          &:after {
            font-size: 45px;
            color: #5bc1b3;
            position: absolute;
            height: 2px;
            left: 40px;
            right: 40px;
            line-height: 0.5;
            background: linear-gradient(to right, #5bc1b3 45%, transparent 45%, transparent),
              linear-gradient(to right, transparent, transparent 55%, #5bc1b3 55%);
            font-family: serif;
          }

          &:before {
            content: '\201C';
            top: 30px;
          }

          &:after {
            content: '\201D';
            bottom: 30px;
          }
          p {
            font-size: 20px;

            @media (max-width: 899px) {
              font-size: 18px;
            }

            @media (max-width: 499px) {
              font-size: 16px;
            }
          }
        }
      }

      button {
        padding: 5px 10px;
        margin: 5px;
        border: none;
        font-weight: bold;

        &:hover {
          background: green;
          color: white;
        }
      }
    }
  }
}
