.statistics {
  background-color: #f7f7f7;
  font-family: 'Times New Roman', Times, serif;
  .stat__list {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: 799px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @media (max-width: 499px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .stat__item {
      display: flex;
      width: 33%;
      justify-content: center;
      align-items: center;

      .stat__item-logo {
        img {
          width: 100px;

          @media (max-width: 799px) {
            width: 70px;
          }
        }
      }

      .stat__item-text {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        font-size: 30px;
        line-height: 1;
        justify-content: center;
        padding-top: 10px;

        @media (max-width: 799px) {
          font-size: 20px;
        }

        .stat__item-number {
          font-weight: bold;
        }
        .stat__item-desc {
          font-size: 15px;

          @media (max-width: 799px) {
            font-size: 13px;
          }
        }
      }
    }

    .stat__item-border {
      border-left: 3px solid #ee8b64;
      border-right: 3px solid #ee8b64;

      @media (max-width: 499px) {
        width: 100%;
        border-left: 0;
        border-right: 0;
        border-top: 3px solid #ee8b64;
        border-bottom: 3px solid #ee8b64;
      }
    }
  }
}
