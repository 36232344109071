// .skeleton {
//   max-width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   box-sizing: border-box;
//   background: #f7f7f7;
//   height: 180px;

//   .card {
//     background: #f7f7f7;
//     border: none;
//     .head {
//       display: flex;
//       align-items: center;
//       max-width: 350px;
//       width: 100%;
//       padding: 30px;

//       .img {
//         height: 75px;
//         width: 75px;
//         background: #656871;
//         border-radius: 50%;
//         position: relative;
//         overflow: hidden;
//       }

//       .details {
//         margin-left: 5px;

//         span {
//           display: block;
//           background: #656871;
//           border-radius: 10px;
//           overflow: hidden;
//           position: relative;
//         }

//         .about {
//           height: 70px;
//           width: 150px;
//         }
//       }

//       .details span::before {
//         animation-delay: 0.2s;
//       }
//     }

//     .head .img::before,
//     .details span::before {
//       position: absolute;
//       content: '';
//       height: 100%;
//       width: 100%;
//       background-image: linear-gradient(
//         to right,
//         #656871 0%,
//         #888b94 20%,
//         #656871 40%,
//         #656871 100%
//       );
//       background-repeat: no-repeat;
//       background-size: 450px 400px;
//       animation: shimmer 1s linear infinite;
//     }

//     .head img::before {
//       background-size: 650px 600px;
//     }
//   }

//   @keyframes shimmer {
//     0% {
//       background-position: -450px 0;
//     }
//     100% {
//       background-position: 450px 0;
//     }
//   }
// }

.skeleton {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: #f7f7f7;
  height: 180px;

  .card {
    background: #f7f7f7;
    border: none;
    .head {
      display: flex;
      align-items: center;
      max-width: 350px;
      width: 100%;
      padding: 30px;

      .img {
        height: 75px;
        width: 75px;
        background: #bfc2ca;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
      }

      .details {
        margin-left: 5px;

        span {
          display: block;
          background: #bfc2ca;
          border-radius: 10px;
          overflow: hidden;
          position: relative;
        }

        .about {
          height: 70px;
          width: 150px;
        }
      }

      .details span::before {
        animation-delay: 0.2s;
      }
    }

    .head .img::before,
    .details span::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
        to right,
        #bfc2ca 0%,
        #b0b3bb 20%,
        #c0c3cc 40%,
        #bfc2ca 60%,
        #cfd2da 80%,
        #bfc2ca 100%
      );
      background-repeat: no-repeat;
      background-size: 450px 400px;
      animation: shimmer 1s linear infinite;
      opacity: 0.7;
    }

    .head img::before {
      background-size: 650px 600px;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -450px 0;
    }
    100% {
      background-position: 450px 0;
    }
  }
}
