.articleList {
  width: 100%;
  min-height: 100vh;

  .main {
    width: 100%;
    display: flex;

    .sidebar {
      flex: 1;
    }

    .articles {
      flex: 5;
      padding: 5px;
      margin: 0 auto;
      width: 900px;
      padding: 5px;
      box-sizing: border-box;
      .article {
        box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.52);
        border-radius: 10px;
        margin: 10px;
        padding: 5px;
        display: flex;
        border: 1px solid rgb(202, 196, 196);

      

        .chapter__and__views {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: left;
          flex-direction: column;
        }

        .img {
          width: 250px;
          height: 150px;
          object-fit: cover;
          padding-right: 20px;
        }

        button {
          padding: 5px 10px;
          margin: 5px;
          border: none;
          font-weight: bold;

          &:hover {
            background: green;
            color: white;
          }
        }
      }
    }
  }
}
