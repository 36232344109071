.titleText {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text_info {
    font-weight: 300;

    .text_info_title {
      font-size: 70px;
      font-family: Oswald, sans-serif;
      font-weight: 700;
      line-height: 0.2;
      padding-bottom: 5px;

      @media (max-width: 1220px) {
        font-size: 60px;
      }

      @media (max-width: 1040px) {
        font-size: 50px;
      }

      @media (max-width: 950px) {
        font-size: 40px;
      }

      @media (max-width: 870px) {
        padding-bottom: 0;
        line-height: 0.1;
      }

      @media (max-width: 675px) {
        font-size: 30px;
      }

      @media (max-width: 550px) {
        font-size: 20px;
      }
    }

    .text_info_title_text {
      font-size: 30px;

      @media (max-width: 1040px) {
        font-size: 25px;
      }

      @media (max-width: 950px) {
        font-size: 20px;
      }

      @media (max-width: 675px) {
        font-size: 15px;
      }

      @media (max-width: 550px) {
        line-height: 0.1;
      }

      @media (max-width: 400px) {
        display: none;
      }

      // @media (max-width: 992px) {
      //   font-size: 25px;
      // }
      // @media (max-width: 876px) {
      //   font-size: 23px;
      // }
      // @media (max-width: 696px) {
      //   font-size: 18px;
      // }
      // @media (max-width: 499px) {
      //   font-size: 14px;
      // }
      // @media (max-width: 399px) {
      //   font-size: 12px;
      // }
    }
  }
}
