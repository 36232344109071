.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  @media (max-width: 650px) {
    margin-top: 30%;
  }

  img {
    max-width: 100%;
  }
}
